import { inject, computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
    name: 'How',
    setup: function () {
        var howStore = inject('howStore');
        var router = useRouter();
        var menuOption = ref(0);
        var authStore = inject('authState');
        var loadData = function () {
            howStore.getHowWorkData();
        };
        var goDashboard = function () {
            router.push({ name: 'tests.index' });
        };
        var menuScroll = function (opt) {
            menuOption.value = opt;
            switch (opt) {
                case 0: {
                    var element0 = document.getElementById('howItWorks_section_0');
                    element0.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                    break;
                }
                case 1: {
                    var element1 = document.getElementById('howItWorks_section_1');
                    element1.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                    break;
                }
                case 2: {
                    var element2 = document.getElementById('howItWorks_section_2');
                    element2.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                    break;
                }
                case 3: {
                    var element3 = document.getElementById('howItWorks_section_3');
                    element3.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                    break;
                }
                default:
                    break;
            }
        };
        onBeforeMount(function () {
            loadData();
        });
        return {
            howWorkData: computed(function () { return howStore.state.howWorkData; }),
            goDashboard: goDashboard,
            menuOption: menuOption,
            menuScroll: menuScroll,
        };
    },
};
